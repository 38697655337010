.page-promotions .hl-theme--title {
  margin: 12px 0 40px;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #272727;
}
.page-promotions__cards {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 32px;
  list-style: none;
}
.page-promotions__card-img {
  border-radius: 12px;
  height: 188px;
  overflow: hidden;
}
.page-promotions__card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-promotions__card-text {
  margin: 16px 0 21px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #272727;
  display: block;
}
@media (max-width: 1280px) {
  .page-promotions__cards {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 24px;
  }
}
@media (max-width: 920px) {
  .page-promotions .hl-theme--title {
    font-size: 28px;
    line-height: 34px;
  }
  .page-promotions__cards {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 16px;
  }
  .page-promotions__card-text {
    margin: 8px 0 12px;
    font-size: 15px;
  }
}