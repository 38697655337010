h1.t-title {
  margin: 12px 0 48px;
  color: #272727;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
}
@media (max-width: 1280px) {
  h1.t-title {
    margin: 16px 0 32px;
    font-size: 32px;
    line-height: 42px;
  }
}
@media (max-width: 920px) {
  h1.t-title {
    margin: 16px 0 24px;
    font-size: 24px;
    line-height: 32px;
  }
}

.t-page__sitemap {
  padding-top: 122px !important;
  padding-bottom: 200px !important;
}
@media (max-width: 1280px) {
  .t-page__sitemap {
    padding-bottom: 124px !important;
  }
}
@media (max-width: 920px) {
  .t-page__sitemap {
    padding-top: 111px !important;
    padding-bottom: 80px !important;
  }
}
@media (max-width: 1280px) {
  .t-page__sitemap .breadcrumbs {
    margin: 32px 0 16px;
  }
}
@media (max-width: 920px) {
  .t-page__sitemap .breadcrumbs {
    margin: 24px 0 16px;
  }
}
.t-page__sitemap-content {
  width: 100%;
}
.t-page__sitemap-content a {
  color: #272727;
  display: inline-block;
  text-decoration: none;
}
.t-page__sitemap-content ul {
  list-style: none;
  padding: 0;
}
.t-page__sitemap-content-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media (max-width: 920px) {
  .t-page__sitemap-content-list {
    max-height: fit-content;
    flex-wrap: nowrap;
  }
}
.t-page__sitemap-content-list > li {
  margin-right: 20px;
  margin-bottom: 48px;
  width: calc(25% - 14.5px);
}
@media (max-width: 1280px) {
  .t-page__sitemap-content-list > li {
    width: 30%;
  }
}
@media (max-width: 920px) {
  .t-page__sitemap-content-list > li {
    margin-right: 0;
    margin-bottom: 24px;
    width: 100%;
    border-bottom: 1px solid #E8E8E8;
  }
}
.t-page__sitemap-content-category {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: #272727;
}
@media (max-width: 920px) {
  .t-page__sitemap-content-category {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 125%;
  }
}
.t-page__sitemap-content-subcategory {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #272727;
}
@media (max-width: 920px) {
  .t-page__sitemap-content-subcategory {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 114%;
  }
}
.t-page__sitemap-content-subcategory:not(:last-child) {
  margin-bottom: 16px;
}