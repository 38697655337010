.daterangepicker {
  margin-top: 16px !important;
  box-shadow: 0px 4px 16px rgba(39, 39, 39, 0.08);
  border-radius: 12px !important;
  border: none !important;
}
.daterangepicker th.next,
.daterangepicker th.prev {
  background: none !important;
}

.daterangepicker:after,
.daterangepicker:before {
  content: none !important;
}

.daterangepicker .drp-calendar.left {
  max-width: none;
}

.daterangepicker.single .ranges {
  margin-top: 0 !important;
  float: none !important;
}

.daterangepicker .calendar-table table {
  border-spacing: 8px !important;
  border-collapse: separate !important;
}

.daterangepicker th.month {
  font-weight: 600;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  border: none !important;
  width: 48px !important;
  height: 48px !important;
  padding: 12px;
  font-size: 16px !important;
  line-height: 24px !important;
  min-width: auto !important;
}

.daterangepicker .calendar-table th.available,
.daterangepicker .calendar-table td.available {
  color: #272727;
  background: #F5F6F7;
  border-radius: 8px !important;
}

.daterangepicker .calendar-table th.disabled,
.daterangepicker .calendar-table td.disabled {
  color: #757C85 !important;
  text-decoration: none;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  color: white !important;
  background-color: #357ebd !important;
  border-radius: 8px !important;
}

@media (max-width: 1280px) {
  .daterangepicker .calendar-table th,
  .daterangepicker .calendar-table td {
    text-align: center !important;
  }
}
@media (max-width: 920px) {
  .daterangepicker .calendar-table td,
  .daterangepicker .calendar-table th {
    padding: 8px;
    width: 40px !important;
    height: 40px !important;
  }
  .daterangepicker {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    top: auto !important;
    width: 100% !important;
    z-index: 3005 !important;
  }
  .daterangepicker .drp-calendar.left {
    padding: 8px;
  }
}