h1.t-title {
    margin: 12px 0 48px;
    color: #272727;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;

    @media (max-width: 1280px) {
        margin: 16px 0 32px;
        font-size: 32px;
        line-height: 42px;
    }

    @media (max-width: 920px) {
        margin: 16px 0 24px;
        font-size: 24px;
        line-height: 32px;
    }
}

.t-page__sitemap {
    padding-top: 122px !important;
    padding-bottom: 200px !important;

    @media (max-width: 1280px) {
        padding-bottom: 124px !important;
    }

    @media (max-width: 920px) {
        padding-top: 111px !important;
        padding-bottom: 80px !important;
    }

    .breadcrumbs {
        @media (max-width: 1280px) {
            margin: 32px 0 16px;
        }

        @media (max-width: 920px) {
            margin: 24px 0 16px;
        }
    }

    &-content {
        width: 100%;

        a {
            color: #272727;
            display: inline-block;
            text-decoration: none;
        }

        ul {
            list-style: none;
            padding: 0;
        }

        &-list {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            @media (max-width: 920px) {
                max-height: fit-content;
                flex-wrap: nowrap;
            }

            > li {
                margin-right: 20px;
                margin-bottom: 48px;
                width: calc(25% - 14.5px);

                @media (max-width: 1280px) {
                    width: 30%;
                }
        
                @media (max-width: 920px) {
                    margin-right: 0;
                    margin-bottom: 24px;
                    width: 100%;
                    border-bottom: 1px solid #E8E8E8;
                }
            }
        }

        &-category {
            margin-bottom: 20px;
            font-weight: 600;
            font-size: 20px;
            line-height: 120%;
            color: #272727;

            @media (max-width: 920px) {
                margin-bottom: 16px;
                font-size: 16px;
                line-height: 125%;
            }
        }

        &-subcategory {
            font-weight: 400;
            font-size: 16px;
            line-height: 120%;
            color: #272727;

            @media (max-width: 920px) {
                margin-bottom: 16px;
                font-size: 14px;
                line-height: 114%;
            }
        }

        &-subcategory:not(:last-child) {
            margin-bottom: 16px;
        }
    }
}